import React, { useState } from 'react';

import MenuCertificateImage from 'assets/images/pages/menu/Menu Certificate.png';
import MenuCoursesImage from 'assets/images/pages/menu/Menu Courses.png';
import MenuCoverImage from 'assets/images/pages/menu/Menu Cover Letter.png';
import MenuResumeImage from 'assets/images/pages/menu/Menu Resume Builder.png';
import MenuSkillImage from 'assets/images/pages/menu/Menu Skill Tree.png';
import MenuVIPImage from 'assets/images/pages/menu/Menu VIP.png';

import { Ranking, Rank, Briefcase, Book, DirectboxNotif, MessageQuestion, Element4, Book1 } from 'iconsax-react';

import classes from './DiscoverMenu.module.scss';

const DiscoverMenu = () => {
    const MenuItems = [
        {
            id: 1,
            title: 'Our Impact',
            image: <Rank size={44} variant='TwoTone' color='#403F3F' />,
            description: 'Unlock the potential of your career in the Pharma industry with our online courses and qualifications.',
        },
        {
            id: 2,
            title: 'Career Hub',
            image: <Briefcase size={44} variant='TwoTone' color='#403F3F' />,
            description: 'At Pharmuni, we specialize in streamlining the hiring process for the pharmaceutical industry.',
        },
        {
            id: 3,
            title: 'Our Mission',
            image: <Book size={44} variant='TwoTone' color='#403F3F' />,
            description: 'Our mission is to create a global network of people and companies that are empowered by the knowledge of their unlimited potential.',
        },
        {
            id: 4,
            title: 'Blog',
            image: <DirectboxNotif size={44} variant='TwoTone' color='#403F3F' />,
            description: 'Learn essential pharma skills and stay updated on regulations, new Pharmuni features, and industry insights—all in our expert blog.',
        },
        {
            id: 5,
            title: 'FAQ',
            image: <MessageQuestion size={44} variant='TwoTone' color='#403F3F' />,
            description: 'Find quick answers to frequently asked questions in our FAQ.',
        },
        {
            id: 6,
            title: 'GMP',
            image: <Element4 size={44} variant='TwoTone' color='#403F3F' />,
            description: 'Stay ahead in the pharmaceutical industry by mastering GMP, GCP (Good Clinical Practices), and GXP.',
        },
        {
            id: 7,
            title: 'Glossary',
            image: <Book1 size={44} variant='TwoTone' color='#403F3F' />,
            description: 'Explore key terms and definitions used in pharmaceutical regulations, compliance, and industry practices.',
        }
    ];

    const [activeItem, setActiveItem] = useState(0);

    return (
        <div className={`${classes.wrapper} row`}>
            <div className="col-5">
                <div className={classes['item-wrapper']}>
                    <div className="row w-100">
                        <div className="col-5">
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(0)} href='https://pharmuni.com/our-impact/'>
                                    Our Impact
                                </a>
                            </div>
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(1)} href='https://pharmuni.com/career-hub/'>
                                    Career Hub
                                </a>
                            </div>
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(2)} href='https://pharmuni.com/our-mission/'>
                                    Our Mission
                                </a>
                            </div>
                            <div className={`${classes['item']} m-0`}>
                                <a onMouseEnter={() => setActiveItem(6)} href='https://pharmuni.com/glossary/'>
                                    Glossary
                                </a>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(3)} href='https://pharmuni.com/blog/'>
                                    Blog
                                </a>
                            </div>
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(4)} href='https://pharmuni.com/faq'>
                                    FAQ
                                </a>
                            </div>
                            <div className={classes['item']}>
                                <a onMouseEnter={() => setActiveItem(5)} href='https://pharmuni.com/gmp'>
                                    GMP
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-7 d-flex flex-column justify-content-center align-items-center gap-2">
                <div className={classes['icon']}>
                    {MenuItems[activeItem].image}
                </div>
                <p>{MenuItems[activeItem].description} </p>
            </div>
        </div>
    );
};

export default DiscoverMenu;