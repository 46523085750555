import React, { useState } from 'react';

import FeatureMenu from './featuremenu/FeatureMenu';
import DiscoverMenu from './discovermenu/DiscoverMenu';

import { I3Dcube, BoxSearch, ArrowRight2 } from 'iconsax-react';

import classes from './ResourcesMenu.module.scss';



const ResourcesMenu = () => {
    const [activeItem, setActiveItem] = useState('feat');
    return (
        <div className={`${classes.wrapper} container`}>
            <div className="row">
                <div className="col-3">
                    <div className={classes['main-item-wrapper']}>
                        <div onMouseEnter={() => setActiveItem('feat')} className={`${classes["main-item"]} ${activeItem === 'feat' ? classes['active'] : '' }`}>
                           <I3Dcube /> Features <ArrowRight2 />
                        </div>
                        <div onMouseEnter={() => setActiveItem('discover')} className={`${classes["main-item"]} ${activeItem === 'feat' ? '' : classes['active'] }`}>
                           <BoxSearch /> Discover  <ArrowRight2 />
                        </div>
                    </div>
                </div>
                <div className="col-9">
                    {activeItem === 'feat' ? <FeatureMenu /> : <DiscoverMenu /> }
                </div>
            </div>
        </div>
    );
};

export default ResourcesMenu;