import React, { useEffect, useState, useRef } from 'react';
import LinkButton from '@core/components/linkbutton/LinkButton';
import Slide from '@mui/material/Slide';
import DashboardMenuDesktop from '@core/components/dashboardMenuDesktop/DashboardMenuDesktop';
import classes from './DashboardMenu.module.scss';

const DashboardMenu = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const menuRef = useRef(null);
    const checkboxRef = useRef(null);

    useEffect(() => {
        if (showMobileMenu) {
            document.body.classList.add(`${classes['stop-scrolling']}`);
        } else {
            document.body.classList.remove(`${classes['stop-scrolling']}`);
        }
        return () => {
            document.body.classList.remove(`${classes['stop-scrolling']}`);
        };
    }, [showMobileMenu]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setTimeout(() => {
                    setShowMobileMenu(false);
                    if (checkboxRef.current) {
                        checkboxRef.current.checked = false;
                    }
                }, 0); // Ensure toggle click is handled first
            }
        };
        
        if (showMobileMenu) {
            document.addEventListener("mouseup", handleClickOutside);
        } else {
            document.removeEventListener("mouseup", handleClickOutside);
        }
        
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [showMobileMenu]);

    return (
        <>
            <div
                className={`${classes['brg-container']}`}
                data-testid="burger-menu"
                role="button"
                tabIndex="0"
                onClick={() => {
                    setShowMobileMenu(!showMobileMenu);
                }}
            >
                <input ref={checkboxRef} className={`${classes['checkbox']}`} type="checkbox" />
                <div className={`${classes['hamburger-lines']}`}>
                    <span className={`${classes['line']} ${classes['line1']}`}></span>
                    <span className={`${classes['line']} ${classes['line2']}`}></span>
                    <span className={`${classes['line']} ${classes['line3']}`}></span>
                </div>
            </div>
            {showMobileMenu && <div className={classes["slider-wrapper"]}></div>}
            <Slide
                direction="left"
                in={showMobileMenu}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 500, exit: 500 }}
            >
                <div className={`${classes['menu-items']}`} data-testid="menu" >
                    <div ref={menuRef} className="d-flex flex-column flex-wrap align-content-start">
                        <DashboardMenuDesktop isMobile={true} />
                    </div>
                </div>
            </Slide>
        </>
    );
};

export default DashboardMenu;
