import React from 'react';
import { useQuery } from 'react-query';
import { AB_Test } from 'services/ab-test';

const useABTest = () => {
    const { data } = useQuery('ab_test_init', AB_Test);
    const ABTest = data?.data || {}; // Accessing `data.data` safely
    const vip_coaching_button_place = ABTest?.vip_coaching_button_place || 'bottom'; // Return 'default' if variant is missing

    return { vip_coaching_button_place }; // Properly returning data
};

export default useABTest;