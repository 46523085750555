export const base64ToArrayBuffer = (base64) => {
    const base64new = base64.split('base64,')[1];
    var binaryString = atob(base64new);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

export const readFile = (file) => {
    try {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        })
    } catch (e) {
        console.warn('failed to detect the orientation')
    }
}

export const downloadFile = (file) => {
    const url = window.URL.createObjectURL(new Blob([file.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.headers["content-disposition"].split("filename=")[1].replace("\"", "").replace("\"", ""));
    document.body.appendChild(link);
    link.click();
}

export const downloadFileURL = async (Url) => {
    try {
        const response = await fetch(Url);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
  
        // Create an anchor element and trigger download
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "downloaded-file.pdf"; // Change filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
  
        // Clean up the blob URL
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Download failed:", error);
      }
}