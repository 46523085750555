import React, { useState, useEffect } from 'react';

import { useRouter } from 'next/router';
import Link from 'next/link';

import classes from './MobileMenu.module.scss';

import HomeLogo from '@core/assets/icons/pharmuni.svg';
import MyUniIcon from '@core/assets/icons/myuni.svg';
import ExploreIcon from '@core/assets/icons/explore.svg';
import WishlistIcon from '@core/assets/icons/wishlist.svg';
import ProfileIcon from '@core/assets/icons/profile.svg';
import VIPIcon from '@core/components/vipIcon/VIPIcon';
import CompanyIcon from 'assets/icons/home-hashtag.svg'
import JobBoardIcon from 'assets/icons/briefcase-icon.svg';
import DashboardIcon from '@core/assets/icons/dashboard.svg';
import { UserOctagon } from 'iconsax-react';


import { BLANK_LAYOUT } from '@core/constants/Layout';
import { isUserLoggedIn } from 'utils/authentication';

const MobileMenu = ({ layout, vipAccess, isB2B, promotion }) => {
	const router = useRouter();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	useEffect(() => {
		setIsAuthenticated(isUserLoggedIn());
	}, []);

	if (layout === BLANK_LAYOUT) {
		return;
	}
	return (
		<>
			<div
				className={`${classes['bottom-nav-gap']} ${classes[layout ? layout : '']
					} d-block d-xl-none`}></div>
			<nav
				data-testid="mobile-nav"
				className={`${classes['nav-menu']} 
					${classes[layout ? layout : '']} d-flex d-xl-none align-items-center`}>
				<div className="container d-flex justify-content-between  align-items-center">
					{!isB2B ? (
						<ul
							className={`${classes['nav-links-mobile']} d-flex justify-content-between m-0 p-0 px-3`}>
							{!isAuthenticated ? (
								<Link
									href="/"
									className={`${router.pathname === '/' ? classes['active-link-home'] : ''}`}>
									<HomeLogo />
									<span>Home</span>
								</Link>
							) : (
								<Link
									href="/user-dashboard"
									className={`${router.pathname === '/user-dashboard' ? classes['active-link'] : ''}`}>
									<DashboardIcon />
									<span>My Dashboard</span>
								</Link>
							)
							}

							<Link
								href="/explore?featured_contents=true"
								className={`${router.pathname === '/explore' ? classes['active-link'] : ''}`}>
								<ExploreIcon />
								<span>Explore</span>
							</Link>
							{isAuthenticated ? (
								<></>
								// <Link
								// 	href="/job-board"
								// 	className={`${router.pathname === '/job-board' ? classes['active-link'] : ''}`}>
								// 	<JobBoardIcon />
								// 	<span>Job board</span>
								// </Link>
							) : (
								<Link
									href="/plans"
									className={`${router.pathname === '/plans' ? classes['active-link'] : ''}`}>
									<UserOctagon />
									Plans
								</Link>
							)
							}

							{/* <Link
								href="/profile"
								className={`${router.pathname === '/profile' ? classes['active-link'] : ''}`}>
								{vipAccess ? <VIPIcon active={router.pathname === '/profile'} /> : <ProfileIcon />}
								<span>Profile</span>
							</Link> */}


						</ul>
					) : (
						<ul
							className={`${classes['nav-links-mobile']} d-flex justify-content-between m-0 p-0 px-3`}>
							<Link
								href="/company/dashboard"
								className={`${router.pathname === '/company/dashboard' ? classes['active-link'] : ''}`}>
								<CompanyIcon />
								<span>My company</span>
							</Link>
							<Link
								href="/company/job-board"
								className={`${router.pathname === '/company/job-board' ? classes['active-link'] : ''}`}>
								<JobBoardIcon />
								<span>Job board</span>
							</Link>
							{/* <Link
								href="/wishlist"
								className={`${router.pathname === '#' ? classes['active-link'] : ''}`}>
								<WishlistIcon />
								<span>Companies</span>
							</Link> */}
							{/* <Link
								href="/profile"
								className={`${router.pathname === '/profile' ? classes['active-link'] : ''}`}>
								{vipAccess ? <VIPIcon active={router.pathname === '/profile'} /> : <ProfileIcon />}
								<span>Profile</span>
							</Link> */}
							{/* <Link
								href="/user-dashboard"
								className={`${router.pathname === '/user-dashboard' ? classes['active-link'] : ''}`}>
								<DashboardIcon />
								<span>My Dashboard</span>
							</Link>	 */}
						</ul>
					)}
				</div>
			</nav>

		</>
	);
};

export default React.memo(MobileMenu);
