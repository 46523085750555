import { isUserLoggedIn } from 'utils/authentication';

import classes from './AppFooter.module.scss';

import PharmuniLogo from '@core/assets/logo/pharmuni-footer.svg';
import InstaIcon from 'assets/icons/footer-instagram.svg';
import TiktokIcon from 'assets/icons/footer-tiktok.svg';
import MailIcon from 'assets/icons/footer-mail.svg';
import LinkedinIcon from 'assets/icons/footer-linkedin.svg';
import Link from 'next/link';
import ApplicationsAppstore from 'assets/images/app-store-icon.png';
import ApplicationsGooglestore from 'assets/images/applications-google-play.svg';

const AppFooter = () => {
	const isAuthenticated = isUserLoggedIn();
	return (
		<div className={`${classes['footer-under-container']} pt-5 pb-3`}>
			<footer className={`${classes['footer-container']} container px-0`}>
				<div className={`${classes['logo-container']}`}>
					<Link className={`${classes['logo']}`} href="/">
						<PharmuniLogo data-testid="pharmuni-footer.svg" />
					</Link>
					<h3 className={`${classes['title']}`}>A brand of Zamann Pharma Support</h3>
					<h4 className={`${classes['copyright']}`}>© All rights reserved</h4>
					<p className={`${classes['info']}`}>
						Address: Zamann Pharma Support GmbH, Siedlerstraße 7 | 68623 Lampertheim, Germany
					</p>
				</div>
				<div className={`${classes['links-container']}`}>
					<h1 className={`${classes['links-header']}`}>Quick Links</h1>
					<ul>
						<li>
							<a href={`${window?.location.origin}/terms-and-conditions/`} rel="noreferrer">
								Terms and Conditions
							</a>
						</li>
						<li>
							<a href={`${window?.location.origin}/privacy-policy/`} rel="noreferrer">
								Privacy Policy
							</a>
						</li>
						<li>
							<a href={`${window?.location.origin}/imprint/`} rel="noreferrer">
								Imprint
							</a>
						</li>
						<li>
							{isAuthenticated ? (
								<Link href="/contact-us">Contact Us</Link>
							):(
								<Link href="/login?next=/contact-us">Contact Us</Link>
							)}
							
						</li>
					</ul>
				</div>
				<div className={`${classes['links-container']}`}>
					<h1 className={`${classes['links-header']}`}>Resources</h1>
					<ul>
						<li>
							<a href={`${window?.location.origin}/career-hub/`} rel="noreferrer">
								Career Hub
							</a>
						</li>
						<li>
							<a href={`${window?.location.origin}/our-mission/`} rel="noreferrer">
								Our Mission
							</a>
						</li>
						<li>
							<a href={`${window?.location.origin}/our-impact/`} rel="noreferrer">
								Our Impact
							</a>
						</li>
						<li>
							<a href={`${window?.location.origin}/blog/`} rel="noreferrer">
								Blog
							</a>
						</li>
						<li>
							<a href={`${window?.location.origin}/glossary/`} rel="noreferrer">
								Glossary
							</a>
						</li>
						<li>
							<Link href="/faq">FAQ</Link>
						</li>
					</ul>
				</div>
				<div className={`${classes['links-container']}`}>
					<h1 className={`${classes['links-header']}`}>Get the app</h1>
					<ul className=" d-flex flex-column gap-2">
						<li>
							<a
								href="https://play.google.com/store/apps/details?id=com.zamann.pharmuni"
								target="blank"
								referrerPolicy="no-referrer">
								<ApplicationsGooglestore />
							</a>
						</li>
						<li>
							<a
								href="https://apps.apple.com/app/pharmuni/id1586543010"
								target="blank"
								referrerPolicy="no-referrer">
								<img src={ApplicationsAppstore.src} alt="Download Pharmuni App – Access On-the-Go Pharmaceutical Training from the App Store" />
							</a>
						</li>
					</ul>
					<h1 className={`${classes['links-header']}`}>Stay in touch</h1>
					<ul className= {`${classes['stay-touch']} d-flex flex-column gap-2`}>
						<li className="d-flex flex-row gap-3">
							<a href="mailto:info@pharmuni.com">
								<MailIcon />
							</a>
							{/* <a
								href="https://www.tiktok.com/@pharmuni_com"
								target="_blank"
								rel="noreferrer"
								data-testid="tiktok-icon">
								<TiktokIcon />
							</a> */}
							<a
								href="https://www.linkedin.com/showcase/pharmuni/"
								target="_blank"
								rel="noreferrer"
								data-testid="linkedin-icon">
								<LinkedinIcon />
							</a>
							<a
								href="https://www.instagram.com/pharmuni_com/"
								target="_blank"
								rel="noreferrer"
								data-testid="insta-icon">
								<InstaIcon />
							</a>
						</li>
					</ul>
				</div>
				<div className={`${classes['links-container']}`}>

				</div>
			</footer>
		</div>
	);
};

export default AppFooter;
