import React, { useEffect, useState } from 'react';

import LinkButton from '@core/components/linkbutton/LinkButton';
import { isUserLoggedIn } from 'utils/authentication';

import Slide from '@mui/material/Slide';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { ArrowDown2 } from 'iconsax-react';

import classes from './BurgerMenu.module.scss';

const BergerMenu = () => {
	const isAuthenticated = isUserLoggedIn();
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	useEffect(() => {
		if (showMobileMenu) {
			document.body.classList.add(`${classes['stop-scrolling']}`);
		} else {
			document.body.classList.remove(`${classes['stop-scrolling']}`);
		}
		return () => {
			document.body.classList.remove(`${classes['stop-scrolling']}`);
		};
	}, [showMobileMenu]);
	return (
		<>
			<div
				className={`${classes['brg-container']}`}
				data-testid="burger-menu"
				role="button"
				tabIndex="0"
				onClick={() =>
					setShowMobileMenu((prevState) => {
						return !prevState;
					})
				}>
				<input className={`${classes['checkbox']}`} type="checkbox" name="" id="" />
				<div className={`${classes['hamburger-lines']}`}>
					<span className={`${classes['line']} ${classes['line1']}`}></span>
					<span className={`${classes['line']} ${classes['line2']}`}></span>
					<span className={`${classes['line']} ${classes['line3']}`}></span>
				</div>
			</div>
			<Slide
				direction="right"
				in={showMobileMenu}
				mountOnEnter
				unmountOnExit
				timeout={{
					enter: 500,
					exit: 500
				}}>
				{
					<div className={`${classes['menu-items']}`} data-testid="menu">
						<div className="d-flex flex-column  align-content-start p-4">
							<ul className="px-0 w-100">
								<li>
									<a href={`/explore/`} rel="noreferrer">
										Explore
									</a>
								</li>
								{/* <li className='pb-4'>
									<a href={`/job-board/`} rel="noreferrer">
										Job Board
									</a>
								</li> */}
								<Accordion sx={{ boxShadow: 'none', paddingLeft: 0, marginBottom: '12px' }} >
									<AccordionSummary
										sx={{ paddingLeft: 0, margin: 0 }}
										expandIcon={<ArrowDown2 />}
										aria-controls="panel1-content"
										id="panel1-header"
									>
										<li className='p-0'>Features</li>
									</AccordionSummary>
									<AccordionDetails>
										<li>
											<a href={`${window?.location.origin}/skill-tree/`} rel="noreferrer">
												Skill Tree
											</a>
										</li>
										<li>
											<a href={`${window?.location.origin}/pharmuni-courses/`} rel="noreferrer">
												Courses
											</a>
										</li>
										<li>
											<a href={`${window?.location.origin}/pharmuni-certificate/`} rel="noreferrer">
												Certificate
											</a>
										</li>
										<li>
											<a href={`${window?.location.origin}/vip-consultation-meeting/`} rel="noreferrer">
												VIP Coaching meeting
											</a>
										</li>
										<li>
											<a href={`${window?.location.origin}/cover-letter-builder/`} rel="noreferrer">
												Coverletter Builder
											</a>
										</li>
										<li className='p-0'>
											<a href={`${window?.location.origin}/resume-builder/`} rel="noreferrer">
												Resume Builder
											</a>
										</li>
									</AccordionDetails>
								</Accordion>
								<Accordion sx={{ boxShadow: 'none', paddingLeft: 0, '&:before': { display: 'none' } }} >
									<AccordionSummary
										sx={{ paddingLeft: 0, margin: 0 }}
										expandIcon={<ArrowDown2 />}
										aria-controls="panel1-content"
										id="panel1-header"
									>
										<li className='p-0'>Discover</li>
									</AccordionSummary>
									<AccordionDetails>
										<li>
											<a href={`${window?.location.origin}/our-impact/`} rel="noreferrer">
												Our Impact
											</a>
										</li>
										<li>
											<a href={`${window?.location.origin}/career-hub/`} rel="noreferrer">
												Career Hub
											</a>
										</li>
										<li>
											<a href={`${window?.location.origin}/blog/`} rel="noreferrer">
												Blog
											</a>
										</li>
										<li>
											<a href={`${window?.location.origin}/our-mission/`} rel="noreferrer">
												Our Mission
											</a>
										</li>
										<li>
											<a href={`${window?.location.origin}/gmp/`} rel="noreferrer">
												GMP
											</a>
										</li>
										<li>
											<a href={`${window?.location.origin}/glossary/`} rel="noreferrer">
												Glossary
											</a>
										</li>
										<li>
											<a href={`${window?.location.origin}/terms-and-conditions/`} rel="noreferrer">
												Terms and Conditions
											</a>
										</li>
										<li>
											<a href={`${window?.location.origin}/privacy-policy/`} rel="noreferrer">
												Privacy Policy
											</a>
										</li>
										<li className='p-0'>
											<a href={`${window?.location.origin}/faq/`} rel="noreferrer">
												FAQ
											</a>
										</li>										
									</AccordionDetails>
								</Accordion>


								<li className='pt-4'>
									{isAuthenticated ? (
										<a href="/contact-us">Contact Us</a>
									) : (
										<a href="/login?next=/contact-us">Contact Us</a>
									)}
								</li>


							</ul>
							<LinkButton
								className={`${classes['login-register-btn']} px-3 py-3`}
								to="/get-started">
								Login / Register
							</LinkButton>
							<ul className="px-0 pb-5">
								{/* <li>
									<a href={`${window?.location.origin}/terms-and-conditions/`} rel="noreferrer">
										Terms and Conditions
									</a>
								</li>
								<li>
									<a href={`${window?.location.origin}/privacy-policy/`} rel="noreferrer">
										Privacy Policy
									</a>
								</li>
								<li>
									<a href={`${window?.location.origin}/faq/`} rel="noreferrer">
										FAQ
									</a>
								</li> */}
							</ul>
						</div>
					</div>
				}
			</Slide >
		</>
	);
};

export default BergerMenu;
